<template>
    <div>
        <div class="main">
            <div class="search">
                <div class="refresh" @click="getUserList"><i class="fa fa-refresh"></i></div>
                <!--파트너 선택-->
                <partner-selector-comp @setPartnerId="setPartnerId"></partner-selector-comp>

                <!--검색조건선택-->
                <el-select size="mini" style="width: 100px;margin-left: 5px" v-model="searchUser.byField"
                           placeholder="검색조건">
                    <el-option :value="managerConst.User.SEARCH_BY_USERNAME" label="아이디"></el-option>
                    <el-option :value="managerConst.User.SEARCH_BY_NICKNAME" label="닉네임"></el-option>
                    <el-option :value="managerConst.User.SEARCH_BY_REFERRER" label="추천인"></el-option>
                    <el-option :value="managerConst.User.SEARCH_BY_REGIP" label="가입IP"></el-option>
                    <el-option :value="managerConst.User.SEARCH_BY_LASTE_LOGIN_IP" label="최근접속IP"></el-option>
                    <el-option :value="managerConst.User.SEARCH_BY_BENEFICIARY" label="예금주"></el-option>
                    <el-option :value="managerConst.User.SEARCH_BY_PHONE" label="휴대폰"></el-option>
                    <el-option :value="managerConst.User.SEARCH_BY_ACNO" label="계좌번호"></el-option>
                    <el-option :value="managerConst.User.SEARCH_BY_OTHERNAME" label="별칭"></el-option>
                </el-select>
                <el-input v-model="searchUser.searchString" size="mini" style="width: 150px;margin-left: 5px"
                          placeholder="검색내용입력"></el-input>
                <el-button type="primary" size="mini" style="margin-left: 5px" @click="seachUser">검색</el-button>

                <!--검색조건선택-->
                <el-select size="mini" style="width: 110px;margin-left: 5px" v-model="selectedType"
                           @change="selectedTypeChange" placeholder="특정회원">
                    <el-option :value="{'type':'all','val':managerConst.YES}">전체</el-option>
                    <el-option :value="{'type':'newuser','val':managerConst.YES}">신규회원</el-option>
                    <el-option :value="{'type':'recharge','val':managerConst.YES}">입금회원</el-option>
                    <el-option :value="{'type':'risk','val':managerConst.YES}">특별관리회원</el-option>
                </el-select>
                <!--레벨-->
                <el-select size="mini" style="width: 100px;margin-left: 5px" v-model="user.rankId"
                           @change="getUserList" placeholder="레벨 선택">
                    <el-option v-for="rank in rankList" :value="rank.id" :label="rank.rankName">{{rank.rankName}}
                    </el-option>
                </el-select>
                <!--그룹-->
                <el-select size="mini" style="width: 100px;margin-left: 5px" v-model="user.groupId"
                           @change="getUserList" placeholder="그룹 선택">
                    <el-option v-for="group in groupList" :value="group.id" :label="group.groupName">
                        {{group.groupName}}
                    </el-option>
                </el-select>
                <!--상태-->
                <!--                <el-select size="mini" style="width: 80px;margin-left: 5px" v-model="selectedStatus"-->
                <!--                           @change="changeStatus" placeholder="회원상태 선택">-->
                <!--                    <el-option :value="managerConst.User.STATUS_ENABLE" label="정상">정상</el-option>-->
                <!--                    <el-option :value="managerConst.User.STATUS_DISABLE" label="블럭">블럭</el-option>-->
                <!--                    <el-option :value="managerConst.User.STATUS_UNAUTHORIZED" label="미인증">미인증</el-option>-->
                <!--                    <el-option :value="managerConst.User.STATUS_AUTHORIZING" label="인증중">인증중</el-option>-->
                <!--                </el-select>-->
                <!--상태-->
                <el-select size="mini" style="width: 110px;margin-left: 5px" v-model="user.utype"
                           @change="getUserList" placeholder="회원타입">
                    <el-option :value="managerConst.User.USER_TYPE_NOMAL" label="일반회원">일반회원</el-option>
                    <el-option :value="managerConst.User.USER_TYPE_FACK" label="가라회원">가라회원</el-option>
                    <el-option :value="managerConst.User.USER_TYPE_MANAGER" label="관리자회원">관리자회원</el-option>
                </el-select>
                <!--설정계좌 선택-->
                <el-select size="mini" style="width: 95px;margin-left: 5px" v-model="user.banktype"
                           @change="getUserList" placeholder="설정계좌">
                    <el-option :value="managerConst.User.BANK_TYPE_NORMAL" label="수동답변">수동답변</el-option>
                    <el-option :value="managerConst.User.BANK_TYPE_BANKCARDID" label="실통장">실통장</el-option>
                    <el-option :value="managerConst.User.BANK_TYPE_FACK_CARD" label="가상계좌 1">가상계좌 1</el-option>
                    <el-option :value="managerConst.User.BANK_TYPE_FACK_CARD_2" label="가상계좌 2">가상계좌 2</el-option>
                    <el-option :value="managerConst.User.BANK_TYPE_FACK_CARD_3" label="가상계좌 3">가상계좌 3</el-option>
                    <el-option :value="managerConst.User.BANK_TYPE_FACK_CARD_4" label="가상계좌 4">가상계좌 4</el-option>
                </el-select>

                <el-select size="mini" style="width: 110px;margin-left: 5px" v-model="orderBy"
                           @change="getUserList" placeholder="회원상태 선택">
                    <el-option value="id DESC" label="가입순정열">가입순정열</el-option>
                    <el-option value="totalrecharge desc" label="총입금정열">총입금정열</el-option>
                    <el-option value="totalexchange desc" label="총출금정열">총출금정열</el-option>
                    <el-option value="reprofit desc" label="손이익정열(+)">손이익정열(+)</el-option>
                    <el-option value="reprofit asc" label="손이익정열(-)">손이익정열(-)</el-option>
                    <el-option value="cash DESC,id DESC" label="머니순정열">머니순정열</el-option>
                    <el-option value="user_point DESC,id DESC" label="포인트순정열">포인트순정열</el-option>
                    <el-option value="last_login_time DESC,id DESC" label="포인트순정열">최근접속순정열</el-option>
                </el-select>

                <el-popover
                        placement="bottom"
                        popper-class="bg-title"
                        width="280" style="margin-left: 5px">
                    <div>
                        <el-button size="mini" type="success" @click="editUser(null,managerConst.MODE_SAVE)">기본등록
                        </el-button>
                        <el-button size="mini" type="success" @click="uploadUserExcel">엑셀등록</el-button>
                        <el-button size="mini" type="success" @click="downLoadUserList" v-if="!isdenymanager">다운로드</el-button>
                    </div>
                    <el-button size="mini" type="success" slot="reference">회원등록
                    </el-button>

                </el-popover>

            </div>

            <div class="search" style="justify-content: flex-start;">
                <div style="width: 50%">
                    <el-button size="mini" type="info"
                               :class="{'active':this.user.status==managerConst.User.STATUS_ENABLE}"
                               @click="selectByUserStatus(managerConst.User.STATUS_ENABLE)">정상회원
                    </el-button>
                    <el-button size="mini" type="info"
                               :class="{'active':this.user.status==managerConst.User.STATUS_DISABLE}"
                               @click="selectByUserStatus(managerConst.User.STATUS_DISABLE)">블럭
                    </el-button>
                    <el-button size="mini" type="info"
                               :class="{'active':this.user.status==managerConst.User.STATUS_UNAUTHORIZED}"
                               @click="selectByUserStatus(managerConst.User.STATUS_UNAUTHORIZED)">미인증
                    </el-button>
                    <el-button size="mini" type="info"
                               :class="{'active':this.user.status==managerConst.User.STATUS_AUTHORIZING}"
                               @click="selectByUserStatus(managerConst.User.STATUS_AUTHORIZING)">인증중
                    </el-button>
                </div>
                <div style="width: 50%">
                    <el-button size="mini" type="warning"
                               v-if="this.user.status == managerConst.User.STATUS_UNAUTHORIZED"
                               @click="updateSelectedUsersStatus(managerConst.User.STATUS_AUTHORIZING)">[인증중]상태로변경
                    </el-button>

                    <el-button size="mini" type="primary"
                               v-if="this.user.status == managerConst.User.STATUS_AUTHORIZING"
                               @click="updateSelectedUsersStatus(managerConst.User.STATUS_ENABLE)">[정상]상태로변경
                    </el-button>
                    <el-button size="mini" style="color: red"
                               v-if="this.user.status == managerConst.User.STATUS_AUTHORIZING"
                               @click="updateSelectedUsersStatus(managerConst.User.STATUS_DISABLE)">[블럭]상태로변경
                    </el-button>
                </div>
            </div>
            <div class="data">
                <el-table
                        :data="userList"
                        style="width: 100%"
                        max-height="730"
                        border
                        @selection-change="handleSelectionChange">
                    <!--더보기-->
                    <el-table-column type="expand" label="더보기" width="60">
                        <template slot-scope="props">
                            <el-form label-position="left" class="demo-table-expand">
                                <el-form-item label="">
                                    <span class="text-green">통신사:</span> {{props.row.rank.telcomp}}
                                </el-form-item>
                                <el-form-item label="">
                                    <span class="text-green">레벨:</span> {{props.row.rank.rankName}}
                                </el-form-item>
                                <el-form-item label="">
                                    <span class="text-green">계좌답변:</span>
                                    <span v-if="props.row.banktype==managerConst.User.BANK_TYPE_NORMAL">수동답변</span>
                                    <span v-if="props.row.banktype==managerConst.User.BANK_TYPE_BANKCARDID">{{props.row.ccrt.title}}</span>
                                    <span v-if="props.row.banktype==managerConst.User.BANK_TYPE_FACK_CARD"
                                          class="text-primary">{{props.row.ccrtFackCard.title}}</span>
                                    <span v-if="props.row.banktype==managerConst.User.BANK_TYPE_FACK_CARD_2"
                                          class="text-danger">{{props.row.ccrtFackCard2.title}}</span>
                                    <span v-if="props.row.banktype==managerConst.User.BANK_TYPE_FACK_CARD_3"
                                          class="text-danger">{{props.row.ccrtFackCard3.title}}</span>
                                    <span v-if="props.row.banktype==managerConst.User.BANK_TYPE_FACK_CARD_4"
                                          class="text-danger">{{props.row.ccrtFackCard4.title}}</span>

                                </el-form-item>
                                <el-form-item label="">
                                    <span class="text-green">스포츠 베팅수:</span> {{props.row.betSportsTotal|comma}}
                                </el-form-item>
                                <el-form-item label="">
                                    <span class="text-green">미니게임 베팅수:</span> {{props.row.betLeisrueTotal|comma}}
                                </el-form-item>
                                <el-form-item label="">
                                    <span class="text-green">가입IP:</span> {{props.row.regip}}
                                </el-form-item>
                                <el-form-item label="">
                                    <span class="text-green">최근로그인IP:</span> {{props.row.lastLoginIp}}
                                </el-form-item>

                            </el-form>
                        </template>
                    </el-table-column>
                    <!--번호-->
                    <el-table-column
                            fixed
                            label="접속"
                            width="50">
                        <template slot-scope="scope">
                                <span v-if="scope.row.loginStatus != managerConst.YES">
                                            <i class="fa fa-ban" style="color: grey;"></i>
                                        </span>
                            <span v-if="scope.row.loginStatus == managerConst.YES">
                                            <i class="fa fa-power-off" style="color: #5ca8fa"></i>
                                        </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="상태"
                            width="50">
                        <template slot-scope="scope">
                            <div v-if="scope.row.status == managerConst.User.STATUS_ENABLE">[정상]</div>
                            <div style="color: red" v-if="scope.row.status == managerConst.User.STATUS_DISABLE">
                                [블럭]
                            </div>
                            <div style="color: #b3d8ff"
                                 v-if="scope.row.status == managerConst.User.STATUS_UNAUTHORIZED">[인증]
                            </div>
                        </template>
                    </el-table-column>

                    <!--번호-->
                    <el-table-column
                            fixed
                            label="번호"
                            width="50">
                        <template slot-scope="scope">
                            {{scope.row.id}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="총판/추천인"
                            width="110">
                        <template slot-scope="scope">
                            <div v-if="null != scope.row.agent"
                                 @click="editPartner(scope.row.partnerId,managerConst.MODE_EDIT)"
                                 style="cursor: pointer">
                                {{scope.row.agent.nickname}}
                            </div>
                            <div v-if="null != scope.row.myReferrer"
                                 @click="editUser(scope.row.myReferrer.id,managerConst.MODE_EDIT)"
                                 style="cursor: pointer;color: slateblue">
                                {{scope.row.myReferrer.username}}
                            </div>
                        </template>
                    </el-table-column>
                    <!--체크박스-->
                    <el-table-column
                            fixed
                            type="selection"
                            width="45">
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="아이디"
                            width="90">
                        <template slot-scope="scope">
                            {{scope.row.username}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="닉네임"
                            width="115">
                        <template slot-scope="scope">
                            <span v-if="scope.row.othername">({{scope.row.othername}})</span>
                            <span @click="editUser(scope.row.id,managerConst.MODE_EDIT)" style="cursor: pointer">
                                   <i class="fa fa-user"
                                      v-if="scope.row.utype == managerConst.User.USER_TYPE_NOMAL"></i> {{scope.row.nickname}}
                                </span>
                            <span class="text-red" v-if="scope.row.repeatip == managerConst.YES">(*)</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="예금주"
                            width="70">
                        <template slot-scope="scope">
                            {{scope.row.beneficiary}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="레벨"
                            width="65">
                        <template slot-scope="scope">
                            {{scope.row.rank.rankName}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="그룹"
                            width="65">
                        <template slot-scope="scope">
                            {{scope.row.userGroup.groupName}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="보유금액"
                            width="80">
                        <template slot-scope="scope">
                            <span :class="{'text-blue': scope.row.cash > 100000}">{{scope.row.cash|comma}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="보유Point"
                            width="80">
                        <template slot-scope="scope">
                            <span :class="{'text-blue': scope.row.userPoint > 100000}">{{scope.row.userPoint|comma}}</span>
                        </template>
                    </el-table-column>

                    <!--가입-->
                    <el-table-column
                            fixed
                            label="가입일짜"
                            width="80">
                        <template slot-scope="scope">
                            <span :class="{'bg-warning' : scope.row.autoclosed === managerConst.NOT}">{{scope.row.createTime|datef('MM월 DD일')}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="최근접속"
                            width="80">
                        <template slot-scope="scope">
                            <span v-if="scope.row.lastLoginTime != null">{{scope.row.lastLoginTime|datef('MM월 DD일')}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="총입금"
                            width="100">
                        <template slot-scope="scope">
                            {{scope.row.totalrecharge|comma}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="총출금"
                            width="100">
                        <template slot-scope="scope">
                            {{scope.row.totalexchange|comma}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="손이익"
                            width="90">
                        <template slot-scope="scope">
                            <span :class="{'text-red': (scope.row.totalrecharge-scope.row.totalexchange) < 0}"> {{(scope.row.totalrecharge-scope.row.totalexchange)|comma}}</span>
                        </template>
                    </el-table-column>


                </el-table>
            </div>
            <div class="pagePanel">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNum"
                        :page-sizes="[25,50, 100, 150, 200]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageTotal">
                </el-pagination>
            </div>
        </div>


        <!--Excel Upload-->
        <el-dialog title="엑셀 회원등록" width="20%" :visible.sync="userExceluploadDialog">
            <el-upload
                    class="upload-demo"
                    ref="upload"
                    :limit="1"
                    :action="uploadAction"
                    :on-success="uploadSuccess"
                    :on-change="fileChanged"
                    :file-list="fileList"
                    :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">회원엑셀 파일을 선택하세요</el-button>
            </el-upload>

            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">취소</el-button>
                <el-button type="primary" @click="submitUpload">확인</el-button>
            </div>
        </el-dialog>

    </div>

</template>

<script>
    import Vue from "vue";
    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {manager} from "../../common/administrator/managerMixin";
    import managerConst from "../../common/administrator/managerConst";
    import {getUserList, updateSelectedUserStatus} from "../../network/manager/userRequest";
    import {Loading} from "element-ui";
    import PartnerSelectorComp from "../../components/administrator/PartnerSelectorComp";
    import {getRankList} from "../../network/manager/rankRequest";
    import {getGroupList} from "../../network/manager/groupRequest";
    import {downloadExcelFile, uploadFile} from "../../network/manager/commonRequest";
    import UserEditToast from "../../components/administrator/UserPanel/UserEditToast";
    import PartnerEditToast from "../../components/administrator/UserPanel/PartnerEditToast";
    import AgentEditToast from "../../components/administrator/UserPanel/AgentEditToast";

    Vue.prototype.$userEditToast = UserEditToast
    Vue.prototype.$partnerEditToast = PartnerEditToast
    Vue.prototype.$agentEditToast = AgentEditToast
    export default {
        name: "ManagerUser",
        components: {PartnerSelectorComp, ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
        mixins: [manager],
        data() {
            return {
                user: {
                    status: managerConst.User.STATUS_ENABLE,
                },
                searchUser: {},
                pageNum: 1,
                pageSize: 25,
                orderBy: 'id DESC',
                pageTotal: 0,
                userList: [],
                editUserId: 0,
                selectedPartnerId: null,
                selectedType: {},
                selectedRank: null,
                selectedStatus: managerConst.User.STATUS_ENABLE,
                rankList: [],
                groupList: [],
                userExceluploadDialog: false,
                uploadAction: '/user/upload_user_excel',
                downloadExcelAction: '/user/download_simple_userinfo',
                excelFile: null,
                fileList: [],
                isdenymanager: this.$store.state.manager.role == managerConst.Manager.MANAGER_NORMAL_SHOWDENY,
            }
        },
        methods: {
            editPartner(id, mode) {
                this.$agentEditToast({'agentId': id, 'mode': mode})
            },
            editUser(id, mode) {
                this.$userEditToast({'userId': id, 'mode': mode})
            },
            addUser(userId) {
                this.editUserId = userId
            },
            updateSelectedUsersStatus(status) {
                let record = {}
                record.status = status
                record.uids = this.user.uids
                this.user.uids = null
                updateSelectedUserStatus(record).then(res => {
                    if (res.data.success) {
                        this.getUserList()
                    }
                })
            },
            selectByUserStatus(status) {
                this.user.status = status
                this.getUserList()
            },
            getUserList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                getUserList(this.user, this.pageNum, this.pageSize, this.orderBy).then(res => {
                    this.pageTotal = res.data.total;
                    this.userList = res.data.data;
                    loadingInstance.close();
                })
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getUserList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getUserList();
            },
            setPartnerId(partnerId) {
                this.pageNum = 1
                this.user = {}
                this.user.status = managerConst.User.STATUS_ENABLE
                this.user.partnerId = partnerId
                this.getUserList()
            },
            seachUser() {
                this.user = this.searchUser
                this.getUserList()
            },
            selectedTypeChange() {
                this.user = {}
                this.user.status = managerConst.User.STATUS_ENABLE
                this.user.isNewUser = null
                this.user.risk = null
                this.user.recharge = null
                switch (this.selectedType.type) {
                    case 'newuser':
                        this.user.isNewUser = this.selectedType.val
                        break;
                    case 'recharge':
                        this.user.recharge = this.selectedType.val
                        break;
                    case 'risk':
                        this.user.risk = this.selectedType.val
                        break;
                }
                this.getUserList()
            },
            changeStatus() {
                this.user = {}
                this.user.status = this.selectedStatus
                this.getUserList()
            },
            uploadUserExcel() {
                this.userExceluploadDialog = true
            },
            submitUpload() {
                uploadFile(this.uploadAction, this.excelFile).then(res => {
                    this.userExceluploadDialog = false
                    this.excelFile = null;
                    this.fileList = []
                    if (res.data.success) {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: res.data.data
                        });
                        this.getUserList()
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 3000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })
            },
            fileChanged(file, fileList) {
                this.excelFile = file.raw;
            },
            uploadSuccess(res, file, fileList) {
                console.log(res)
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.user.uids = [];
                this.multipleSelection.map(g => {
                    this.user.uids.push(g.id)
                });
                console.log(this.user)
            },
            downLoadUserList() {
                downloadExcelFile(this.downloadExcelAction, this.user).then(res => {
                    console.log(res)
                    if (res.data.type) {
                        const blob = new Blob([res.data], {
                            type: "application/vnd.ms-excel"
                        });
                        let link = document.createElement('a');
                        link.style.display = 'none'
                        link.href = URL.createObjectURL(blob);
                        link.setAttribute('download', '회원정보.xls');
                        link.click();
                        link = null;
                        this.$message.success('다운로드 완료');
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
        },
        created() {
            if (this.$route.query.status) {
                this.user.status = parseInt(this.$route.query.status);
                this.selectedStatus = parseInt(this.$route.query.status)
            }
            this.getUserList()
            getRankList({'status': managerConst.ENABLE}, 1, 100).then(res => {
                this.rankList = res.data.data
            })
            getGroupList({'status': managerConst.ENABLE}, 1, 100).then(res => {
                this.groupList = res.data.data
            })
            this.$bus.$on('userEdited', (calbc) => {
                this.getUserList()
            })

        },
        watch: {}
    }
</script>

<style scoped>
    .active{
        background-color: gold!important;
        color: #353539;
    }
</style>